




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
 

 

nav a:active {

  font-weight: bold;
  text-decoration: none;
}

 



.saksh_table tr td {
  border : 1px solid #aaa; 
  text-align: center;
  padding: 2px 10px;
}

.saksh_table tr th {
  border : 1px solid #aaa; 
  text-align: center;
  padding: 2px 10px;
}



.pr10{

  margin-left: 20px;
 
}